import { Route, Outlet, Navigate } from 'react-router';
import { SelectPlan } from './SelectPlan';
import { Login } from './Login';
import { Payment } from './Payment';
import { Complete } from './Complete';
import AppFooter from '@/components/elements/AppFooter';
import { useSearchParams } from 'react-router-dom';
import './styles.css';

export function SubscriberLanding() {
  const [searchParams] = useSearchParams();
  // const queryString = searchParams.toString();

  return <>
    <Route path='subscribe' element={<Container/>} >
      {/* <Route path='' element={<Navigate to={'select-plan?'+queryString}/>}/> */}
      <Route path='' Component={SelectPlan} />
      <Route path='sign-up' Component={Login} />
      <Route path='create-account' Component={Login} />
      <Route path='login' Component={Login} />
      <Route path='payment' Component={Payment}/>
      <Route path='thank-you' Component={Complete}/>
      <Route path='welcome-back' Component={Complete}/>
    </Route>
  </>;
}

function Container() {
  return <>
    <div className='sl flex-grow flex flex-col' style={{backgroundColor: '#F7F7F7'}}>
      <div className='flex flex-row justify-center mb-[100px] sm:mb-[24px]'>
        <div className='max-w-[1024px] flex flex-grow flex-row h-20 items-center gap-[24px]'>
          <img
            alt={'logo'}
            src={'/icons/logo.svg'}
            className={'cursor-pointer h-8 w-8'}
          />
          <h1 className="font-bold text-[32px]" style={{fontWeight: 'bolder', fontFamily: 'Chivo,sans-serif', color: '#1E222A'}}>
            MxM News
          </h1>
        </div>
      </div>
      <Outlet/>
    </div>
    <AppFooter/>
  </>
}

/**
c=?
c=?
c=?&plan=?
c=?&plan=?
c=?&plan=?&new=yes|no 
c=?&plan=?&new=yes|no
c=?
*/